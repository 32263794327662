// App.js
import React, { Suspense, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Hourglass } from 'react-loader-spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import routes from './routes';

import NotFoundPage from './components/notFound_screen';

function App()
{
  const [isLoading, setIsLoading] = useState( true );

  useEffect( () =>
  {
    const timeout = setTimeout( () =>
    {
      setIsLoading( false );
    }, 2000 );

    return () => clearTimeout( timeout );
  }, [] );

  return (
    <Suspense fallback={ <div style={ fallbackStyle }><Hourglass
      visible={ true }
      width="200"
      colors={ ['#306cce', '#72a1ed'] }
      ariaLabel="hourglass-loading"
    /></div> }>
      <Routes>
        { routes.map( ( route, index ) => (
          <Route key={ index } path={ route.path } element={ isLoading ? <div style={ fallbackStyle }><Hourglass
            visible={ true }
            width="200"
            colors={ ['#306cce', '#72a1ed'] }
            ariaLabel="hourglass-loading"
          /></div> : <route.component /> } />
        ) ) }
        <Route path="*" element={ <NotFoundPage /> } />
      </Routes>
    </Suspense>
  );
}

const fallbackStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#ffffff',
};

export default App;
