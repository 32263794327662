// routes.js
import { lazy } from 'react';

const HomePage = lazy( () => import( './components/dashboard_screen' ) );
const GamesComponents = lazy( () => import( './components/games_screen' ) );
const GamesDetailsComponents = lazy( () => import( './components/games_screen/games_details/index' ) );
const VideoComponents = lazy( () => import( './components/videos_screen' ) );
const MusicComponents = lazy( () => import( './components/music_screen' ) );
const LearningComponents = lazy( () => import( './components/learning_screen' ) );
const DownloadsComponents = lazy( () => import( './components/downloads_screen' ) );
const DownloadsDetailsComponents = lazy( () => import( './components/downloads_screen/downlord_details' ) );
const PunjabiReadingComponents = lazy( () => import( './components/learning_screen/punjabi_reading_screen/punjabi_reading_listing' ) );
const SentenceMakingComponents = lazy( () => import( './components/learning_screen/sentance_making_screen/sentance_making_list' ) );
const ShabadkoshComponents = lazy( () => import( './components/learning_screen/shabadkosh_screen/shabadkosh_listing_page' ) );
const ContactComponents = lazy( () => import( './components/contact_screen' ) );
const MusicDetailsSection = lazy( () => import( './components/music_screen/music_details_section' ) );
const VideoDetailsSection = lazy( () => import( './components/videos_screen/video_details_categorylist_section' ) );
const PunjabiReadingDetailsSection = lazy( () => import( './components/learning_screen/punjabi_reading_screen/punjabi_reading_details' ) );
const TestimonialDetailsSection = lazy( () => import( './components/dashboard_screen/home_testimonial_Section/testimonal_details_section' ) );
const SentanceMakingDetailsSection = lazy( () => import( './components/learning_screen/sentance_making_screen/sentance_making_details' ) );
const ShabadkoshDetailsSection = lazy( () => import( './components/learning_screen/shabadkosh_screen/shabadkosh_details_page' ) );

const routes = [
    { path: '/', component: HomePage },
    { path: '/testimonial_Details/:id', component: TestimonialDetailsSection },
    { path: '/game', component: GamesComponents },
    { path: '/game/game_Details', component: GamesDetailsComponents },
    { path: '/video', component: VideoComponents },
    { path: '/video_Details/:category', component: VideoDetailsSection },
    { path: '/music_list', component: MusicComponents },
    { path: '/music_list/music_Details/:uuid', component: MusicDetailsSection },
    { path: '/download', component: DownloadsComponents },
    { path: '/download/download_Details/:id', component: DownloadsDetailsComponents },
    { path: '/learning', component: LearningComponents },
    { path: '/learning/punjabi_Reading', component: PunjabiReadingComponents },
    { path: '/learning/punjabi_Reading/punjabi_Reading_Details/:id', component: PunjabiReadingDetailsSection },
    { path: '/learning/sentence_Making', component: SentenceMakingComponents },
    { path: '/learning/sentence_Making/sentence_Making_Details/:id', component: SentanceMakingDetailsSection },
    { path: '/learning/shabadkosh', component: ShabadkoshComponents },
    { path: '/learning/shabadkosh/shabadkosh_Details/:id', component: ShabadkoshDetailsSection },
    { path: '/contact', component: ContactComponents }
];

export default routes;
